.topdedropdown{
    font-family: "Comfortaa","Roboto" !important;
    font-size: 0.874rem !important;
}
.formcontrol fieldset{
    border: 0px;
    border-color:white;
}

.selectdropdown{
    background-color: white;
    font-family: "Comfortaa","Roboto" !important;
    font-size: 0.875rem !important;
}
.selectlabel{
    color:#4db23d !important;
    font-family: "Comfortaa","Roboto" !important;
    font-size: 0.874rem !important;
    margin-top: -8px;
}
.lblshrink{
    top:10px !important;
    margin-top: 0px;
}
.drdicon{
    color:#4db23d !important;
}
.drdselect{
    padding: 14.5px 13px 2.5px !important;
    text-align: left !important;
    text-overflow: 'ellipsis' !important;
    white-space: 'nowrap' !important;
    overflow: 'hidden' !important;
}
.menustyle{
    font-family: "Comfortaa","Roboto" !important;
   
}