.textfield {
    background-color: white;
    font-family: "Comfortaa","Roboto" !important;
    font-size: 0.875rem !important;
  }
  .shrink {
    color:#4db23d !important;
    font-family: "Comfortaa","Roboto" !important;
    top: 11px !important;
    margin-top: 0px;
    font-size: 0.874rem !important;
  }
  .textfield input{
    padding: 8.5px 13px 8.5px !important;
  }
  .notchedoutline{
    border-width:0px !important;
  }