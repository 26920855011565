.Dcftabs {
    font-family: "Comfortaa","Roboto" !important;
}
.tabicon{
    max-width: 40px;
}
.tabtext{
    text-transform: initial !important;
    color:white;
    font-family: "Comfortaa","Roboto";
}
.tab{
    border-bottom: 2px solid white !important;
    margin-right: 5px !important;
}
.MuiTouchRipple-child{
    background-color: 'red';
}

@media only screen and (max-width: 1025px) {
    .Dcftabs .tab{
        min-width: 10px !important;
        font-size:0.6rem !important;
     }
     .tabicon{
        max-width: 30px;
    }
}
@media only screen and (max-width: 769px) {
    .Dcftabs .tab{
        min-width: 15px !important;
        font-size:0.9rem !important;
     }
     .tabicon{
        max-width: 60px;
    }
}
@media only screen and (max-width: 500px) {
    /* Styles to be applied when the screen width is less than 500px */
     .Dcftabs .tab{
        min-width: 10px !important;
        font-size:0.6rem !important;
     }
     .tabicon{
        max-width: 30px;
    }
  }
  @media only screen and (max-width: 400px) {
    /* Styles to be applied when the screen width is less than 500px */
     .Dcftabs .tab{
        min-width: 10px !important;
        font-size:0.6rem !important;
        padding: 6px 10px;
     }
     .tabicon{
        max-width: 25px;
    }
  }
  @media only screen and (max-width: 350px) {
    /* Styles to be applied when the screen width is less than 500px */
     .Dcftabs .tab{
        min-width: 10px !important;
        font-size:0.5rem !important;
        padding: 5px 8px;
     }
     .tabicon{
        max-width: 25px;
    }
  }