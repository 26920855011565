#products {
    background-image: url('../../../assets/img/Section3-Backdrop.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.products {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
}

.card-green {
    background-color: #4db33da4 !important;
    height: 180px;
}

.icon {
  max-width: 60px;
}

.circles-container {
  padding-top: 10px;
}

.circles {
    width: 500px;
    height: 380px;
    background-image: url('../../../assets/img/circles3.svg');
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .circles {
    width: 500px;
    height: 380px;
    margin-top:-50px;
    background-size: contain;
  }
    #products .font-header {
        font-size: 2.4rem;
    }
}

@media (max-width: 1280px) and (min-width: 600px) {
    .card-green {
        height: 155px;
    }
}