#contact {
  background-image: url('../../../assets/img/Boston_Map_Geometry.png');
  background-size: cover;
  background-position: center;
}

.contact {
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.logo-image {
    filter: grayscale(100%) brightness(90%) contrast(10000000%);
    opacity: 0.4; 
    margin: auto;
    display: block;
}

.footer-container {
    background-color: black;
}

.copyright-text {
    font-size: 0.8rem;
}

#footer .row > * {
    padding-right: 0;
    padding-left: 0;
}

.email-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    font-size: 0.75rem !important;
}

.subscribe-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
