.header{
    color:#4db23d;
}
.body{
    color:white;
    font-size: 0.75rem;
}
.topdoyouknow{
    padding: 30px;
    background-repeat: 'no-repeat';
    background-size: 'cover';
    height: 365px;
    width: 550px;
    position: 'relative';
    overflow:'hidden'
}
@media only screen and (max-width: 1025px) {
    .topdoyouknow{
        height: 365px !important;
        width: 450px !important;
    }
}
@media only screen and (max-width: 990px) {
    .topdoyouknow{
        margin-top: 20px;
        height: 400px !important;
        width: 97% !important;
    }
}
@media only screen and (max-width: 769px) {
    .topdoyouknow{
        margin-top: 20px;
        height: 400px !important;
        width: 97% !important;
    }
}
@media only screen and (max-width: 426px) {
    .topdoyouknow{
        margin-top: 20px;
        height: 380px !important;
        width: 97% !important;
    }
}
@media only screen and (max-width: 376px) {
    .topdoyouknow{
        margin-top: 20px;
        height: 350px !important;
        width: 97% !important;
    }
}
@media only screen and (max-width: 351px) {
    .topdoyouknow{
        margin-top: 20px;
        height: 310px !important;
        width: 97% !important;
    }
  }

@media only screen and (max-width: 321px) {
  .topdoyouknow{
      margin-top: 20px;
      height: 300px !important;
      width: 97% !important;
  }
}


