#stats {
  background-image: url('../../../assets/img/Section2-Backdrop.png');
  background-size: cover;
  background-position: center;
}

.stat-header {
  font-size: 3.8rem;
  position: relative;
  padding-right: 0.8em;
  margin-bottom: 0;
}

.stat-percentage {
  font-size: 2.3rem;
  margin: 0 0 7px 5px;
}
.stat-text {
  font-size: 1rem;
}

@media (max-width: 992px) {
  #stats .font-header {
    font-size: 1.3rem;
    margin-bottom: 2rem !important;
  }
  .stat-header {
    font-size: 2rem;
    padding-right: 0;
    width: 80px;
  }

  .stat-header::after {
    bottom: 0.15em;
  }

  .stat-text {
    font-size: 0.9rem;
    text-align: center !important;
  }
}

@media (max-width: 1199px) {
  .stat-header {
    font-size: 2rem;
  }
  .stat-percentage {
    font-size: 1.5rem;
    margin-bottom: 3px;
  }
}

@media (min-width: 1200px) {
  #stats .font-header {
    margin-top: 25px;
    margin-bottom: 80px;
  }
}

.stat-header::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.1em;
  border-bottom: 3px solid #4db23d;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.stat-image {
  margin-bottom: 5px;
}