#dcf {
  background-image: url('../../../assets/img/Section4-Backdrop.png');
  background-size: cover;
  background-position: center;
}

.dcf-calc {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
}

.what-we-do-text-container {
  height: 530px;
}

.what-we-do-text-description {
  font-size: 1.4rem;
}