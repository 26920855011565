.sliderlogo img{
    max-width: 25px;
}
.slidertext{
    font-size: 0.85rem;
    text-align: center;
}
.vertical{
    font-family: "Comfortaa","Roboto" !important;
}
.horizontal{
    font-family: "Comfortaa","Roboto" !important;
}
.verticalslider{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 225px;
    width: 50px;
    margin: 0 auto;
}