.toplocation{
    
}

.topformlocation{
    color : white;
    max-width: 566px;
}
.formlocation{
    
}
@media only screen and (max-width: 769px) {
    .formcontent{
        min-height: auto;
    }
}
