.flip-card-container {
    background-color: transparent;
    min-width: 300px;
    height: 180px;
    /*perspective: 10000px; */ /* This adds a 3D effect, lower value = more 3D */
}

@media (max-width: 400px) {
    .flip-card-container {
        background-color: transparent;
        min-width: 260px;
        height: 180px;
        /* perspective: 10000px; */ /* This adds a 3D effect, lower value = more 3D */
    }
}

@media (max-width: 1280px) and (min-width: 600px) {
    .flip-card-container {
        height: 155px;
    }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1.7s;
  transform-style: preserve-3d;
}

.flipped {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
}
