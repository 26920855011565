#home {
  position: relative;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    background-image: url('../../../assets/img/videoPoster.jpg');
    background-size: cover;
}

video {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.btn-circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.icon-box {
  background: rgba(255, 255, 255, 0.7);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.icon-box i {
  margin-bottom: 10px;
}

@media (max-width: 576px) {
    .masthead {
        min-height: 77vh;
    }
}

@media (max-width: 992px) {
  .masthead {
    min-height: 80vh;
  }
}

@media (min-width: 1200px) {
  .masthead {
    min-height: 100vh;
  }
}

.btn-cucumber {
    color: #fff !important;
    background-color: #4db23d !important;
    border-color: #4db23d !important;
}
.btn-cucumber:hover {
  color: #fff !important;
  background-color: #4db23d !important;
  border-color: #4db23d !important;
}

a.nav-link {
  color: #4db23d;
}

a.nav-link:hover,
a.nav-link:active,
.nav-item .active {
  color: #fff !important;
}

.video-background:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #2a5555;
  opacity: 0.7;
}


